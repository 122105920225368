import { Enum } from './Enum';

export default new Enum({
    NAO_DEFINIDO: { value: 0, text: ' ' },
    PICKING: { value: 1, text: 'Picking' },
    ARMAZENAGEM: { value: 2, text: 'Armazenagem' },
    NOTA_REMESSA_PENDENTE: { value: 3, text: 'Nota de Remessa Pendente' },
    DIVERGENCIAS: { value: 4, text: 'Divergências' },
    TRANSFERENCIA_ENTRE_DEPOSITOS: { value: 5, text: 'Transferência Entre Depósitos' },
    CONCLUIR: { value: 6, text: 'Concluir' }
});
