<template>
    <div id="principal" class="container-fluid p-0 m-0">
        <div class="sticky-top card-header-filter mx-0 p-2 shadow-sm w-100">
            <input-pesquisa ref='inputLocalizarItem' v-focus v-model="localizarItem" @input="localizar($event)" :placeholder="descricaoCampoBusca"></input-pesquisa>
            <label v-if="percentualConferido == 100" class="text-muted p-0 mt-2 mb-0 fz-80">
                Todos os itens já foram conferidos!
            </label>
            <label v-else class="text-muted p-0 mt-2 mb-0 fz-80">
                <strong>{{totalItensConferido}} </strong> itens conferidos de <strong>{{totalItensAConferir}}. </strong>
            </label>
            <progress-bar 
                style="margin-top: -20px;"
                size="small" 
                text-fg-color="gray" 
                text-align="right" 
                text-position="top" 
                :val="percentualConferido" 
                :text="descPercentualConferido" 
                :bar-color="percentualConferido === 100 ? '#34ab56' : '#6fbbff'">
            </progress-bar>
        </div>
        
        <div class="input-group input-group-sm px-2 pt-1 card-data">
            <card-requisicoes-mobile
                :lista="listaItens"
                :etapa="etapa"
                :itemSelecionado="requisicaoSelecionada"
                :itemBipado="localizarItem"
                @limpar-conferidos="limparConferidos($event)"
                @conferir="conferir($event)"
                @onRequisicaoselecionada="onRequisicaoselecionada($event)"
            >
            </card-requisicoes-mobile>
        </div>

        <div class="fixed-bottom bg-white">
            <div class="btn-group mb-0 px-2 w-100 flex justify-content-end">
                <button :class="['btn btn-default m-1', isMobile ? 'btn-block' : '']" @click="liberarRequisicao">Liberar {{ requisicoes.OpcaoFiltro | descBotaoLiberarBloqueio }}</button>
                <button :class="['btn btn-secondary m-1', isMobile ? 'btn-block' : '']" @click="finalizar">Finalizar</button>
            </div>
        </div>
        <!-- Leitor Código de Barras  -->
        <leitor-codigo-barras v-model="leitorCodigoBarras.retorno" :ativo="leitorCodigoBarras.estaAtivo" />
    </div>
</template>

<script>
import loading from '@/common/loading';
import { mapGetters } from 'vuex';
import swal from '@/common/alerts';
import leitorCodigoBarras from '@/components/leitor-codigo-barras.vue';
import cardRequisicoesMobile from './components/card-requisicoes-mobile.vue';
import EtapaProcesso from '@/domain/etapa-processo';
import AcaoRequisicaoWMS from '@/domain/acao-requisicao-wms';
import TipoSolicitacao from '@/domain/tipo-solicitacao';
import InputPesquisa from '../../components/input-pesquisa.vue';
import ProgressBar from 'vue-simple-progress';

export default {
    name: 'produto-pesquisa',
    props: ['requisicoes'],
    components: {
        leitorCodigoBarras,
        cardRequisicoesMobile,
        InputPesquisa,
        ProgressBar
    },
    data() {
        return {
            EtapaProcesso,
            AcaoRequisicaoWMS,
            tipoMensagem: { Sucesso: 1, Erro: 2, Aviso: 3 },
            telaPronta: false,
            idsRequisicoes: null,
            etapa: null,
            localizarItem: '',
            requisicaoSelecionada: null,
            listaItens: [],

            leitorCodigoBarras: {
                estaAtivo: false,
                retorno: null
            }
        };
    },
    filters: {
        descBotaoLiberarBloqueio: function (value) {
            return value == TipoSolicitacao.REQUISICAO_WMS.value ? 'Requisição' : 'Operação';
        },
        descOperacao: function (value) {
            var total = value && this.$_.sum(value);
            return total ? total + ' Operaç' + (total <= 1 ? 'ão' : 'ões') : '';
        }
    },
    mounted() {
        // 1 - picking, 2 - armazenagem, 3 - Nota de Remessa
        this.etapa = this.requisicoes.Etapa;
        this.$store.dispatch('uiState/updateNavbar', this.tituloTela());
        this.obterItensRequisicoes();
    },

    watch: {
        'leitorCodigoBarras.retorno': function (result) {
            if (this.leitorCodigoBarras.estaAtivo) {
                this.leitorCodigoBarras.estaAtivo = false;
            }

            if (result && result.codigoBarras) {
                this.localizar(result.codigoBarras);
            }
        },
        produto: function (newVal) {
            this._abrirProduto(newVal);
        }
    },
    computed: {
        ...mapGetters({
            isMobile: 'appData/ehWMSMobile',
            permissoes: 'requisicoesWMS/permissoesWMS'
        }),
        descricaoCampoBusca: function () {
            //Caso esteja na etapa de picking e alguns dos depositos nao controla localização, podera ser bipado tanto por localização quanto produto
            let title = '';
            if (this.etapa == EtapaProcesso.PICKING.value && this.listaItens.some((s) => { return !s.DepositoOrigemControlaLocalizacao })){
                title = 'Informe a Localização ou Cód. Produto';
            }else if(this.etapa == EtapaProcesso.PICKING.value && this.listaItens.every((s) => { return s.DepositoOrigemControlaLocalizacao })){
                title = 'Informe a Localização do Produto'
            }
            else {
                title = 'Cód. Produto ou Referência'
            }
            return title;
        },
        totalItensAConferir() {
            let qtde = this.$_.sum(this.$_.map(this.listaItens, this.etapa == EtapaProcesso.PICKING.value ? 'QuantidadeSolicitada' : 'QuantidadeSeparada'));
            return qtde;
        },
        totalItensConferido() {
            let qtde = this.$_.sum(this.$_.map(this.listaItens, 'QuantidadeConferida'));
            return qtde;
        },
        percentualConferido() {
            let percent = (this.totalItensConferido / this.totalItensAConferir) * 100
            return percent;
        },
        descPercentualConferido() {
            let percent = Math.round((this.totalItensConferido / this.totalItensAConferir) * 100)
            return percent+ "%";
        }
    },
    methods: {
        obterItensRequisicoes() {
            var self = this;
            loading.exibir();
            self.idsRequisicoes = this.$_.map(self.requisicoes.Itens, 'IdWMSRequisicao');
            var parametrosItens = {
                IdRequisicoesWMS: self.idsRequisicoes
            };
            this.$store
                .dispatch('requisicoesWMS/obterItensRequisicao', parametrosItens)
                .then((response) => {
                    self.telaPronta = true;
                    if (response) {
                        self.listaItens = [];
                        var lista = response;
                        var grupos = self.$_.groupBy(lista, function (a) {
                            return (
                                a.CodigoProduto +
                                '|' +
                                (a.Referencia || '') +
                                '|' +
                                a.DescricaoProduto +
                                '|' +
                                (self.etapa == EtapaProcesso.PICKING.value ? a.SiglaLocalizacaoAtual : a.SiglaLocalizacaoDestino)
                            );
                        });

                        var idx = 0;
                        self.$_.forIn(grupos, function (v, k) {
                            var chave = k.split('|');
                            var req = v.filter(function (x) {
                                return x;
                            })[0];
                            let qtdeSolicitada = self.$_.sum(self.$_.map(v, 'QuantidadeSolicitada'))
                            let qtdeSeparada = self.$_.sum(self.$_.map(v, 'QuantidadeSeparada'))
                            let qtdeConferida = self.$_.sum(
                                    self.$_.map(v, function (x) {
                                        return self.etapa == EtapaProcesso.PICKING.value ? x.QuantidadeSeparada : x.QuantidadeConferida;
                                    })
                                )
                            let itemConferido = self.etapa == EtapaProcesso.PICKING.value ? (qtdeSeparada == qtdeSolicitada) : (qtdeSeparada == qtdeConferida ) 
                            var item = {

                                Index: idx,
                                CodigoProduto: chave[0],
                                Referencia: chave[1],
                                DescricaoProduto: chave[2],
                                QuantidadeSolicitada: qtdeSolicitada,
                                QuantidadeSeparada: qtdeSeparada,
                                QuantidadeConferida: qtdeConferida,
                                IdsRequisicao: self.$_.uniq(self.$_.map(v, 'IdWMSRequisicao')),
                                DepositoControlaLocalizacao: req.DepositoControlaLocalizacao,
                                DepositoOrigemControlaLocalizacao: req.DepositoOrigemControlaLocalizacao,
                                IdDepositoOrigem: req.IdDepositoOrigem,
                                NomeDepositoOrigem: req.NomeDepositoOrigem,
                                IdTipoSolicitacao: req.IdTipoSolicitacao,
                                SiglaLocalizacao: chave[3],
                                LocalizacaoPrimaria: req.LocalizacaoPrimaria,
                                SaldoLocalizacao: req.SaldoLocalizacao,
                                Aberto: false,
                                itemConferido: itemConferido,

                                Conferencias: self.$_.map(v, function (x, i) {
                                    var r = self.$_.find(self.requisicoes.Itens, { IdWMSRequisicao: x.IdWMSRequisicao });
                                    var conferida = self.etapa == EtapaProcesso.PICKING.value ? x.QuantidadeSeparada : x.QuantidadeConferida;
                                    var solicitada = self.etapa == EtapaProcesso.PICKING.value ? x.QuantidadeSolicitada : x.QuantidadeSeparada;
                                    return {
                                        Index: i,
                                        CodigoProduto: chave[0],
                                        Referencia: chave[1],
                                        DescricaoProduto: chave[2],
                                        IndicePai: idx,
                                        IdWMSRequisicaoItem: x.IdWMSRequisicaoItem,
                                        DataRequisicao: r.DataRequisicao,
                                        TipoRequisicaoDescricao: r.TipoRequisicaoDescricao,
                                        VendedorNome: r.VendedorNome,
                                        Documento: r.Documento,
                                        Serie: r.Serie,
                                        NomeClienteFornecedor: r.NomeClienteFornecedor,
                                        LocalizacaoAtual: x.LocalizacaoAtual,
                                        SiglaLocalizacaoAtual: x.SiglaLocalizacaoAtual,
                                        LocalizacaoPrimaria: x.LocalizacaoPrimaria,
                                        LocalizacaoConfirmada: '',
                                        QuantidadeSolicitada: x.QuantidadeSolicitada,
                                        QuantidadeSeparada: x.QuantidadeSeparada,
                                        QuantidadeConferida: conferida,
                                        QuantidadeEnviar: solicitada - conferida <= 0 ? 0 : 1,
                                        IdDepositoOrigem: x.IdDepositoOrigem,
                                        NomeDepositoOrigem: x.NomeDepositoOrigem,
                                        DepositoOrigemControlaLocalizacao: x.DepositoOrigemControlaLocalizacao,
                                        DepositoControlaLocalizacao: x.DepositoControlaLocalizacao,
                                        IdDepositoDestino: x.IdDepositoDestino,
                                        NomeDepositoDestino: x.NomeDepositoDestino,
                                        LocalizacaoDestino: x.LocalizacaoDestino,
                                        SiglaLocalizacaoDestino: x.SiglaLocalizacaoDestino,
                                        LocalizacaoPrimariaDestino: x.LocalizacaoPrimariaDestino,
                                        SaldoLocalizacao: x.SaldoLocalizacao,
                                        IdRequisicao: x.IdWMSRequisicao,
                                        
                                    };
                                })
                            };

                            item.QuantidadeDivergente = item.QuantidadeConferida - self._quantidadeSolicitadaSeparada(item);
                            self.listaItens.push(item);
                            idx++;
                        });
                        // Ordena a lista por Localizacao
                        self.ordenaListaItens();
                    }
                })
                .catch((err) => {
                    this.$helper.globalErrorHandler(err, 'Não foi possível obter os dados necessários, tente novamente');
                    this.$router.push({ name: 'lista-requisicoes' });
                })
                .finally(() => loading.ocultar());
        },

        voltarParaPainel() {
            this.$router.go(-1);
        },
        ordenaListaItens(){            
            this.listaItens = this.$_.orderBy(this.listaItens, ['itemConferido','SiglaLocalizacao'], ['asc','asc']);
        },
        _quantidadeSolicitadaSeparada(item) {
            return this.etapa == EtapaProcesso.PICKING.value ? item.QuantidadeSolicitada : item.QuantidadeSeparada;
        },
        onRequisicaoselecionada(item){
            this.requisicaoSelecionada = item
        },
        localizar(value) {
            var self = this;
            if (!value?.length) {
                if (self.requisicaoSelecionada) {
                    self.requisicaoSelecionada.Aberto = false;
                    self.requisicaoSelecionada = null;
                    this.$refs.inputLocalizarItem.limparCriterio()
                    document.getElementById('input-localizar').focus(); 
                }
                return;
            }

            let item, mensagem;
            let mensagemProdutoNaoEncontrado = `Produto: </br> <strong>"${self.localizarItem}"</strong></br> não encontrado!`
            let mensagemLocalizacaoNaoEncontrada = `Localização: </br> <strong>"${self.localizarItem}"</strong></br> não encontrada!`
            self.localizarItem = value;
                
            //Buscar por Localização
            if (self.etapa == EtapaProcesso.PICKING.value) {
                mensagem = mensagemLocalizacaoNaoEncontrada;
                item = self.listaItens.find((x) => {
                    return x.SiglaLocalizacao === self.localizarItem;
                });

                // Se for picking e não encontrou a localização, faz a tentativa de busca pelo produto para
                //  os casos de depósitos de origem que não possuem controle de localização WMS
                if(!item){
                    mensagem = mensagemProdutoNaoEncontrado;
                    item = self.listaItens.find((x) => {
                        return !x.DepositoOrigemControlaLocalizacao &&( x.CodigoProduto == self.localizarItem || x.Referencia == self.localizarItem);
                    });
                }
            }

            //Buscar por Produto
            else if (self.etapa == EtapaProcesso.ARMAZENAGEM.value) {
                mensagem = mensagemProdutoNaoEncontrado;
                item = self.listaItens.find(function (x) {
                    return x.CodigoProduto == self.localizarItem || x.Referencia == self.localizarItem;
                });
            }
            if (item) {
                if (!item.Aberto) item.Aberto = true;
                self.requisicaoSelecionada = item; //{ ...item, Aberto: true };
            } else if (self.localizarItem) {
                swal.exibirMensagemAlerta(mensagem);
            }
        },

        _atualizarQuantidadesProduto(item) {
            item.QuantidadeConferida = this.$_.sum(this.$_.map(item.Conferencias, 'QuantidadeConferida'));
            item.QuantidadeDivergente = item.QuantidadeConferida - this._quantidadeSolicitadaSeparada(item);
            item.itemConferido = !item.QuantidadeDivergente 
        },

        conferir(item) {
            var self = this;
            loading.exibir();
            var parametrosConferencia = {
                IdWMSRequisicaoItem: item.IdWMSRequisicaoItem,
                QuantidadeConferida: item.QuantidadeEnviar,
                SiglaLocalizacao: item.LocalizacaoConfirmada || null
            };
            this.$store
                .dispatch('requisicoesWMS/conferirItem', parametrosConferencia)
                .then(() => {
                    item.QuantidadeConferida += item.QuantidadeEnviar;
                    if (self.etapa == EtapaProcesso.PICKING.value) {
                        item.QuantidadeEnviar = item.QuantidadeSolicitada - item.QuantidadeConferida <= 0 ? 0 : 1
                        if (item.QuantidadeSolicitada == item.QuantidadeConferida) self.localizar();
                    }
                    else {
                        item.QuantidadeEnviar = item.QuantidadeSeparada - item.QuantidadeConferida <= 0 ? 0 : 1;
                        if (item.QuantidadeSeparada == item.QuantidadeConferida) self.localizar();
                    }
                    item.LocalizacaoConfirmada = '';
                    
                    let itemPai = self.listaItens.filter((f) => f.Index == item.IndicePai)[0]
                    self._atualizarQuantidadesProduto(itemPai);
                    
                    loading.ocultar();
                    swal.exibirToastSucesso('Produto Conferido.');

                    self.ordenaListaItens()
                })
                .catch((err) => {
                    this.$helper.globalErrorHandler(err, 'Não foi possível obter os dados necessários, tente novamente');
                })
                .finally(() => loading.ocultar());
        },

        limparConferidos(item) {
            // faz a limpeza da conferencia. Se não informar item,
            // limpa todos os itens de todas as requisicoes
            var self = this;
            var parametrosLimpeza = {
                IdRequisicoesWMS: item ? item.IdsRequisicao : self.idsRequisicoes,
                CodigoProduto: item ? item.CodigoProduto : null
            };
            this.$store
                .dispatch('requisicoesWMS/limparConferidos', parametrosLimpeza)
                .then(() => {
                    // reset na quantidade conferida da linha do produto
                    var limpaItem = function (produto) {
                        produto.Conferencias.forEach(function (x) {
                            x.QuantidadeConferida = 0;
                            x.QuantidadeEnviar = 1;
                        });
                    };
                    if (item) {
                        limpaItem(item);
                        self._atualizarQuantidadesProduto(item);
                    } else {
                        self.listaItens.forEach(function (x) {
                            limpaItem(x);
                            self._atualizarQuantidadesProduto(x);
                        });
                    }
                    self.loading.ocultar();
                })
                .catch((err) => {
                    this.$helper.globalErrorHandler(err, 'Não foi possível obter os dados necessários, tente novamente');
                })
                .finally(() => loading.ocultar());
        },

        finalizar() {
            var self = this;
            let _concluirRequisicaoSemArmazenagem = self.etapa == EtapaProcesso.PICKING.value
                && self.requisicoes.OpcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value 
                && self.permissoes.ConcluirRequisicaoSemArmazenagem

            let mensagemDivergencia = ''
            if (self.totalItensAConferir != self.totalItensConferido) {
                mensagemDivergencia = '<span class="text-danger font-weight-bold">ATENÇÃO! Existem produtos na listagem que ainda não foram conferidos ou que estão com saldo divergente.</span>'
            }

            if (_concluirRequisicaoSemArmazenagem){
                swal.exibirPerguntaFinalizacaoEtapa('Finalizar Requisição?', mensagemDivergencia,'Concluir a Requisição sem Realizar a Etapa de Armazenagem', (resposta) => {
                    if (resposta) {
                        self.finalizarConferencia(resposta.value)
                    }
                });   
            } else{
                swal.exibirPergunta('Finalizar Requisição?', mensagemDivergencia, (resposta) => {
                    if (resposta) {
                        self.finalizarConferencia()
                    }
                });
            }
        },
        finalizarConferencia(finalizaSemArmazenagem){
            var self = this;
            var parametrosFinalizacao = {
                IdRequisicoesWMS: self.idsRequisicoes,
                FinalizarPickingSemArmazenagem: !!finalizaSemArmazenagem
            };
            
            loading.exibir();
            this.$store
                .dispatch('requisicoesWMS/finalizarConferencia', parametrosFinalizacao)
                .then(() => {
                    // reset na quantidade conferida da linha do produto
                    loading.ocultar();
                    swal.exibirToastSucesso('Conferência Finalizada com sucesso.');
                    this.$router.go(-1);
                })
                .catch((err) => {
                    this.$helper.globalErrorHandler(err, 'Não foi possível obter os dados necessários, tente novamente');
                })
                .finally(() => loading.ocultar());
        },
        async liberarRequisicao() {
            var self = this;
            let acao = '';
            if (self.etapa == EtapaProcesso.PICKING.value) acao = AcaoRequisicaoWMS.AGUARDANDO_PICKING.value;
            else if (self.etapa == EtapaProcesso.ARMAZENAGEM.value) acao = AcaoRequisicaoWMS.AGUARDANDO_ARMAZENAGEM.value;

            var params = {
                IdRequisicoesWMS: self.idsRequisicoes,
                AcaoRequisicao: acao
            };
            loading.exibir();
            await this.$store
                .dispatch('requisicoesWMS/alterarAcoesRequisicoesWMS', params)
                .then(() => {
                    this.$router.go(-1);
                })
                .catch((err) => {
                    this.$helper.globalErrorHandler(err, 'Não foi possível concluir essa ação, tente novamente');
                })
                .finally(() => loading.ocultar());
        },
        tituloTela() {
            var self = this;
            let prefix = self.etapa == EtapaProcesso.PICKING.value ? EtapaProcesso.PICKING.text : EtapaProcesso.ARMAZENAGEM.text;
            let sufix = self.requisicoes.OpcaoFiltro == TipoSolicitacao.REQUISICAO_WMS.value ? ' Requisições' : ' Operações';
            return prefix + sufix;
        }
    }
};
</script>

<style scope lang="scss">
.card-data {
    margin-top: 0px;
    margin-bottom: 3.525rem;
}
.size-font-label {
    font-size: 0.875rem;
}
.label-orange {
    text-align: left;
    font-family: Dosis, sans-serif;
    color: #ffb200;
}
.inputQuantidade {
    max-width: 90px;
}
.border-secondary {
    border-color: #ff9200 !important;
    border-width: 3px !important;
}
.border-primary {
    border-color: #5b2e90 !important;
    border-width: 3px !important;
}
.card-header-filter {
    margin-top: 0px;
    background-color: #f9f9f9;
}

.modal-finalizar-requisicao .swal2-actions .btn-primary {
    margin-right: 10px !important;
}
.swal2-container .swal2-label {
  font-size: 0.8rem!important;
}
.swal2-container #swal2-checkbox{
    width: 50px!important;
    height: 50px!important;
    cursor: pointer;
}

</style>
