<template>
  <div class="flex-grow-1 m-0">
    <div class="input-group">
      <input
        v-model="criterio"
        :disabled="disabled"
        type="text"
        class="form-control form-control-sm"
        ref="refInputLocalizar"
        :id="Id"
        @keydown.enter.tab.prevent="onAtualizarCriterio()"
        @blur="onLimparCriterio($event)"
        :placeholder="placeholder"
      />
      <div class="input-group-append">
        <button v-if="criterio && criterio.length" class="btn-icon-xs btn-clear" @click="limparCriterio" :disabled="disabled" title="Limpar campo de filtro">
          <i class="icon icon-lx-close"></i>
        </button>

        <button class="btn btn-primary btn-sm float-right" :disabled="disabled" @click="onAtualizarCriterio">
          <i class="icon icon-lx-search"></i>
        </button>
        <!-- Abrir câmera para ler código de barras (somente mobile com camera) -->
        <button v-if="isMobile && videoDevice" :disabled="disabled" class="btn btn-secondary btn-sm float-right" @click="abrirLeitorCodigoBarras()">
          <i class="fas fa-camera"></i>
        </button>
      </div>
    </div>
    <leitor-codigo-barras v-model="leitorCodigoBarras.retorno" :ativo="leitorCodigoBarras.estaAtivo" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import leitorCodigoBarras from '@/components/leitor-codigo-barras.vue';

export default {
  name: 'input-pesquisa',
  components: {
    leitorCodigoBarras,
  },
  props: {
    value: {
      default: [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    Id: {
      type: String,
      default: 'input-localizar',
    },
  },
  data() {
    return {
      criterio: '',
      leitorCodigoBarras: {
        estaAtivo: false,
        retorno: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'appData/ehWMSMobile',
      videoDevice: 'uiState/hasVideoDevice',
    }),
  },
  watch: {
    'leitorCodigoBarras.retorno': function (result) {
      if (this.leitorCodigoBarras.estaAtivo) {
        this.leitorCodigoBarras.estaAtivo = false;
      }

      if (result && result.codigoBarras) {
        this.criterio = result.codigoBarras;
        this.onAtualizarCriterio();
      }
    },
  },
  methods: {
    onAtualizarCriterio() {
      if (this.criterio) {
        this.$emit('input', this.criterio);
      } else {
        this.limparCriterio();
      }
    },
    onLimparCriterio($event) {
      switch ($event.type) {
        case 'blur':
          if ($event.type === 'blur' && !this.criterio) {
            this.limparCriterio();
          }
          break;
        case 'click':
          this.limparCriterio();
          break;
      }
    },
    limparCriterio() {
      this.criterio = '';
      this.$emit('input', []);
    },
    abrirLeitorCodigoBarras: function () {
      this.leitorCodigoBarras.estaAtivo = true;
    },
  },
};
</script>
<style scoped>
.btn-clear {
  color: #ee9ca4;
  background-color: #fff;
  border-color: #f3828e;
}
</style>
